module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sebastian Ljunggren","short_name":"sebb.be","start_url":"/","background_color":"#099","theme_color":"#077","display":"standalone","icon":"src/images/logo.svg","icon_options":{"purpose":"maskable"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
